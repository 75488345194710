<template>
  <Modal v-if="!consentGiven" :is-modal-open="isModalOpen" :is-chat="true" @close-modal="isModalOpen = false"  @consent-updated="consentGiven = $event"  @start-chat="startChatApprove"
  />
  <div :class="$style.container">
    <div :class="$style.upper">
      <div :class="$style.upperInner">
        <div v-if="config.title" :class="$style.textContainer">
          <i :class="config.icon" />
          <span v-if="config.shop" :class="$style.titleShop" v-html="config.title" />
          <span v-else :class="$style.title" v-html="config.title" />
        </div>
        <div v-if="config.text" :class="$style.text">
          {{ config.text }}
        </div>
        <form
          v-if="config.input || config.dropdown || config.worldwide || config.jobs"
          method="GET"
          @submit.prevent="handleSubmit"
        >
          <div :class="$style.inputContainer">
            <template v-if="config.input">
              <input
                v-model="inputField"
                :class="$style.inputField"
                type="text"
                :placeholder="config.input.placeholderText"
                aria-label="postalcode"
              />
              <div :class="$style.button" @click="handleSubmit">
                <input
                  type="submit"
                  title="Suchen"
                  value=""
                  :class="$style.submitButton"
                  aria-label="submit postalcode"
                />
                <i class="fas fa-search" />
              </div>
            </template>
            <CustomSelect
              v-else-if="config.dropdown"
              colour="white"
              :select-options="config.dropdown.dropdownValues"
              select-value=""
              track-by="name"
              label="name"
              :placeholder="config.dropdown.dropdownPlaceholder"
              style="width: 100%; max-width: 25rem"
              @on-change="handleSelectSubmit"
            />
            <CustomSelect
              v-else-if="config.jobs"
              colour="white"
              :select-options="regionSelectOptions"
              select-value=""
              :placeholder="config.jobs.dropdownPlaceholder"
              style="width: 100%; max-width: 25rem"
              @on-change="handleRegionSelect"
            />
            <CustomSelect
              v-if="config.jobs"
              :key="`jobCompanySelectKey-${dropdownOneValue}`"
              :class="$style.secondSelect"
              colour="white"
              :select-options="companySelectOptions"
              select-value=""
              :placeholder="config.jobs.dropdownPlaceholderB"
              style="width: 100%; max-width: 25rem"
              :style="{ visibility: showDropdownTwo ? 'visible' : 'hidden' }"
              @on-change="handleCountrySelect"
            />
            <CustomSelect
              v-else-if="config.worldwide"
              colour="white"
              :select-options="regionSelectOptions"
              select-value=""
              :placeholder="config.worldwide.dropdownPlaceholder"
              style="width: 100%; max-width: 25rem"
              @on-change="handleRegionSelect"
            />
            <CustomSelect
              v-if="config.worldwide && showDropdownTwo"
              :key="`worldwideCountrySelectKey-${dropdownOneValue}`"
              :class="$style.secondSelect"
              colour="white"
              :select-options="countrySelectOptions"
              select-value=""
              :placeholder="config.worldwide.dropdownPlaceholder"
              style="width: 100%; max-width: 25rem"
              @on-change="handleCountrySelect"
            />
          </div>
        </form>
        <template v-if="config.static">
          <p :class="$style.staticText">
            {{ config.static.phone }}
          </p>
          <p :class="$style.text">
            <a :href="mailtoLink">{{ config.static.email }}</a>
          </p>
        </template>
        <template v-if="config.shop">
          <p :class="$style.staticText">
            {{ config.shop.phone }}
          </p>
          <div :class="$style.text" v-html="config.shop.opening" />
        </template>
      </div>
    </div>
    <div v-if="config.buttons" :class="$style.lower">
      <template v-for="(button, index) in config.buttons" :key="index">
        <a v-if="button.linkUrl" :class="$style.link" :href="button.linkUrl">
          <div :class="$style.imageContainer">
            <i :class="button.icon" />
          </div>
          <div :class="$style.linkText" v-html="button.text" />
        </a>
        <div v-else :class="$style.link" @click="startChat">
          <div :class="$style.imageContainer">
            <i :class="button.icon" />
          </div>
          <div :class="$style.linkText" v-html="isChatActive ? button.text : button.offlineText" />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import axios from 'axios'
import CustomSelect from '../Select/Select.vue'
import { ref, computed, onMounted, watch } from 'vue'
import { regionDataUrl } from '../../shared/globals'
import Modal from '../Modal/Modal.vue'
import cmpVendorIDs from '../../globals/constants/cmpVendorIDs'
import { getMarketingConsent } from '../../globals/cookiebot'
const vendor = cmpVendorIDs.MICROSOFT_LIVE_CHAT
import { getVendorConsent, registerConsentListeners } from '../../globals/consentmanager'
const consentGiven = ref(window.Cookiebot ? getMarketingConsent() : getVendorConsent(vendor.id))
const isModalOpen = ref(false);

const props = defineProps({
  config: {
    type: Object,
    default: null
  }
})

const apiData = ref(null)
const companySelectOptions = ref([])
const countrySelectOptions = ref([])
const dropdownOneValue = ref('')
const inputField = ref('')
const isChatActive = ref(false)
const regionSelectOptions = ref([])
const showDropdownTwo = ref(false)
const wtTrack = window.wtTrack

const handleSubmit = (e) => {
  e.preventDefault()
  wtTrack({ cat: 'SALESBOXCLICK' }, { inputIdent: inputField.value })
  window.location = `${props.config.input.submitUrl}${inputField.value}`
}

const handleSelectSubmit = (value) => {
  if (value.submitUrl) {
    wtTrack({ cat: 'SALESBOXCLICK' }, { inputIdent: value.name })
    window.location = value.submitUrl
  }
}

const handleRegionSelect = (value) => {
  if (value !== '') {
    companySelectOptions.value = []
    countrySelectOptions.value = []
    dropdownOneValue.value = value
    showDropdownTwo.value = true

    if (props.config.jobs) {
      const targetgroups = []
      for (let region of apiData.value.items) {
        if (
          region.filterCategories.country === dropdownOneValue.value ||
          value === props.config.resetSelectOption
        ) {
          for (let targetgroup of region.filterCategories.targetgroup) {
            targetgroups.push(targetgroup)
          }
        }
      }
      companySelectOptions.value.push(props.config.resetSelectOption)
      companySelectOptions.value.push(...filterUniqueObjects(targetgroups))
    } else if (apiData.value.regions) {
      for (let region of apiData.value.regions) {
        if (region.name === dropdownOneValue.value) {
          for (let country of region.countries) {
            countrySelectOptions.value.push(country.name)
          }
        }
      }
    }
  }
}

const handleCountrySelect = (value) => {
  wtTrack({ cat: 'SALESBOXCLICK' }, { inputIdent: `${dropdownOneValue.value} - ${value}` })

  if (props.config.worldwide) {
    window.location = `${props.config.worldwide.submitUrl}&cnEntry=${dropdownOneValue.value}&country=${value}`
  }
  if (props.config.jobs) {
    if (props.config.resetSelectOption === value) value = ''
    if (props.config.resetSelectOption === dropdownOneValue.value) dropdownOneValue.value = ''

    window.location = `${props.config.jobs.submitUrl}?targetgroup=${value}&country=${dropdownOneValue.value}`
  }
}
const startChat = () => {
  if (!consentGiven.value) {
    isModalOpen.value = true;
    return;
  }
  triggerChat();
  }
  const startChatApprove = () => {
    setTimeout(() => {
    triggerChat();
  }, 4000);
  }
  const triggerChat = () => {
  if (window.LC_API && window.LC_API.is_loaded() && window.LC_API.open_chat_window) {
      window.LC_API.open_chat_window();
      wtTrack({ cat: 'LIVECHATBUTTONCLICK' });
      return;
  }
  if (window?.Microsoft?.Omnichannel?.LiveChatWidget?.SDK?.startChat) {
    window.Microsoft.Omnichannel.LiveChatWidget.SDK.startChat();
    wtTrack({ cat: 'LIVECHATBUTTONCLICK' });
    return;
  }
}
const filterUniqueObjects = (data) => {
  return data.filter((value, index, self) => {
    return self.indexOf(value) === index
  })
}

const initApiData = (data) => {
  if (props.config.jobs) {
    apiData.value = data
    regionSelectOptions.value.push(
      props.config.resetSelectOption,
      ...filterUniqueObjects(apiData.value.items.map((region) => region.filterCategories.country))
    )
  } else if (data.regions) {
    apiData.value = data
    regionSelectOptions.value.push(...apiData.value.regions.map((region) => region.name))
  }
}

const checkLCAgentsAvailability = () => {
  if (window.LC_API && window.LC_API.agents_are_available) {
    isChatActive.value = window.LC_API.agents_are_available()
    window.removeEventListener('livechatReady', checkLCAgentsAvailability)
  } else {
    window.addEventListener('livechatReady', checkLCAgentsAvailability)
  }
}

const mailtoLink = computed(() => {
  if (props.config.static) {
    return `mailto:${props.config.static.email}`
  }
  return ''
})

function cleanTitleAttributes() {
  const docLinks = document.querySelectorAll('.HeaderBox__docLink');
  if(docLinks) {
    docLinks.forEach(link => {
    if (link.title) {
      link.title = link.title.replace(/<[^>]*>?/gm, "");
    }
  });
  }
}
const checkVendorConsent = () => {
  consentGiven.value = getVendorConsent(vendor.id);
};

const registerConsentListenersWithDelay = (checkVendorConsent) => {
  const intervalId = setInterval(() => {
    if (typeof window.__cmp === 'function') {
      registerConsentListeners(checkVendorConsent);
      clearInterval(intervalId);
    }
  }, 100);
  setTimeout(() => clearInterval(intervalId), 5000);
};

onMounted(() => {
  if (window.cmpConsentType === 'consentmanager') {
    registerConsentListenersWithDelay(checkVendorConsent);
    setTimeout(() => {
      checkVendorConsent();
    }, 1000);
  }
  cleanTitleAttributes();
  let dataUrl = '';
  if (props.config.worldwide) {
    dataUrl = window.regionDataUrl || regionDataUrl;
  } else if (props.config.jobs) {
    dataUrl = props.config.jobs.jsonUrl;
  }

  axios
    .get(dataUrl)
    .then((response) => initApiData(response.data))
    .catch((error) => console.error(error));

  checkLCAgentsAvailability();
});
watch(consentGiven, (newConsent) => {
  toggleChatWidget(newConsent);
});
function toggleChatWidget(consentGiven) {
  const chatIframe = document.getElementById("Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window");

  if (chatIframe) {
    if (consentGiven) {
      chatIframe.style.display = "block";
    } else {
      chatIframe.style.display = "none";
    }
  }
}
watch(() => consentGiven.value, (newValue) => {
  if (!newValue) {
    isModalOpen.value = false;
  }
});

</script>

<style module lang="scss">
@import './SalesContainer.scss';
</style>
